import axios from 'axios';
import { axiosApi, API_BACK } from '../conf/configurationProd';
import authHeader from './utils/authHeader';
import handleResponse from './utils/handleResponse';

function ComisionesPagadas(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}comisiones/pagadas`, data, {
      ...requestOptions,
      responseType: 'arraybuffer',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function gruposTransporte(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}cargar/pasajeros/grupos`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function excelComisionesDetailReps(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${data}`, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function ComisionesDetailReps(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${data}`, {
      ...requestOptions,
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function codeProveedor(tipo, id) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}servicio/code/proveedor/${tipo}/${id}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function buscarProveedores(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}cupones/proveedores`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function incentivesProveedores(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}proveedores/incentives`, data, {
      ...requestOptions,
      responseType: 'arraybuffer',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function testPDF(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}factura/proveedor`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function downloadPDF(folio) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}factura/proveedor/download/${folio}`, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function listaLocaciones(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}lista/locaciones`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function verPresupuestos(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}listarpresupuesto`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reportePresupuestosLocacionVenta(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}presupuesto/locacion/mensual`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function periodosCortes(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}periodos/cortes`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function periodosCortesAlta(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}periodos/cortes/alta`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function repLocacionPresupuesto(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}rep/presupuesto/save`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function repLocacionPresupuestoDelete(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}rep/presupuesto/delete`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function repLocacionPresupuestoGet(id, presupuesto) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}rep/presupuesto/list/${id}`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function repLocacionPresupuestoGetFecha(id, presupuesto) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}rep/presupuesto/fecha/list/${id}`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function repLocacionPresupuestoUpdate(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}rep/presupuesto/update`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function crearPresupuesto(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}crear/presupuesto`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function confirmBudget(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}confirmar/presupuesto`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reportePresupuesto(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/presupuesto`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reportePresupuestoPDF(all) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}pdf/presupuesto`, all, {
      ...requestOptions,
      responseType: 'arraybuffer',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reportePresupuestoEXCEL(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}excel/presupuesto`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reporteProduccionAnual(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/produccion/optimizado`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function ReporteProduccionAnualProveedor(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/produccion/proveedores`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reportePresupuestoEXCELxPeriodo(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/presupuesto`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function cuponesConciliados(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}report/coinciliated-coupons`, data, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function costoExcursionesMoneda(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cupones/costo-excursiones/moneda`, data, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function cuponesNoConciliados(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cupones/no/conciliados`, data, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function cuponesCostosPDF(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cupones/costos/PDF`, data, {
      ...requestOptions,
      responseType: 'arraybuffer',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function cuponesCostosEXCEL(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cupones/costos/excel`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function cuponesCostosEXCELUSD(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cupones/costos/excel/usd`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function productividadXproveedorEXCEL(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}proveedores/produccion/excel`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function productividadXproveedorPDF(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}proveedores/produccion/pdf`, data, {
      ...requestOptions,
      responseType: 'arraybuffer',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function cuponesConciliadosPDF(all) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cupones/conciliados/PDF`, all, {
      ...requestOptions,
      responseType: 'arraybuffer',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function cuponesConciliadosEXCEL(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cupones/conciliados/EXCEL`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function cuponesConciliadosvalorEXCEL(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cupones/conciliadosvalor/EXCEL`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function cuponesConciliadosvalorAuditoriaEXCEL(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cupones/conciliadosvalorauditoria/EXCEL`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function cuponesNoConciliadosEXCEL(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cupones/no/conciliados/EXCEL`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function cuponesNoConciliadosPDF(all) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cupones/no/conciliados/PDF`, all, {
      ...requestOptions,
      responseType: 'arraybuffer',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function canales() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}canales`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function comisiones() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}rates`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function tipoCambioDia() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}tipocambio/dia`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function hotelesReporte() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}hoteles`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function proveedoresReporte() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}proveedores`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function hoteles(id) {
  return axiosApi
    .get(`${API_BACK}hoteles/crm/${id}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function locaciones(id, user_id) {
  return axiosApi
    .get(`${API_BACK}locaciones/hoteles/${id}/${user_id}`, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reps() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}usuarios/list`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function supervisor() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}usuarios/list/supervisor`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function repList() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}usuarios/list/reps`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function excel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}excel`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function excelTwo(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}excel/two`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function excelContabilidad(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}excel/contabilidad`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function excelExportar(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}excel/diario`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function excelExportarPagos(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}excel/pagos`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function excelReps(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}excel/reps`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function locacionUser(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}usuario/locacion`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function show(url) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(url, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function showByID(id) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}reserva/show/${id}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function successPage(id) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}reserva/show/reserva/${id}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function products() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}productos/canal`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function productosReporte() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}productos/canal`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function save(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}saveBookin`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
// Nuevo endpoint con middleware
function saveBooking(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}save-booking`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function saveFirma(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}saveFirma`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function saveFirmaRetro(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}savefirmaretro`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
// function saveFirmaSupervisor(data) {
//   const requestOptions = {
//     headers: authHeader(),
//   };
//   return axiosApi
//     .post(`${API_BACK}saveFirmaSupervisor`, data, requestOptions)
//     .then((response) => response)
//     .catch((error) => handleResponse(error));
// }
// function saveFirmaRep(data) {
//   const requestOptions = {
//     headers: authHeader(),
//   };
//   return axiosApi
//     .post(`${API_BACK}saveFirmaRep`, data, requestOptions)
//     .then((response) => response)
//     .catch((error) => handleResponse(error));
// }
// function saveFirmaCliente(data){
//   const requestOptions = {
//     headers: authHeader()
//   };
//   return axiosApi
//   .post(`${API_BACK}saveFirmaCliente`, data, requestOptions)
//   .then((response) => response)
//   .catch((error) => handleResponse(error))
// }
function enviarCorreos(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}enviarCorreos`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function update(id, data) {
  return axiosApi
    .post(`${API_BACK}reserva/edit/${id}`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function updateByItem(id, data) {
  return axios
    .post(`${API_BACK}reserva/edit/item/${id}`, data, {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function confirmacion(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axios
    .post(`${API_BACK}confirmacion/proveedor`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function pagoRestante(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}pagoRestante`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reporteGlobal(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/global/total`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteDiarioVenta(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reportediarioventa`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reporteGlobalPdf(all) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/global/total/pdf`, all, {
      ...requestOptions,
      responseType: 'arraybuffer',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reportePickups(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/pickups`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reporteComissionReps(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/reps/ventas`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reporteProduccion(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/produccion`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reporteTransporte(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/transportacion`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteTransporteCoste(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/transportacion/coste`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteTransporteTraslados(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/transportacion/traslados`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteTransportellegada(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/transportacion/llegada`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteTransporteops(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/transportacion/ops`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteTransporteopsmail(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/transportacion/opsmail`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function adicionarCupon(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}adicionar/cupon`, data, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function AdicionarFactura(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}adicionar/factura`, data, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function buscarFacturas(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}buscar/facturas`, data, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function facturaEXCEL(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}pdf/EXCEL`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function editarFactura(id) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}buscar/factura/${id}`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reporteGerentes(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/gerentes/ventas`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteCancelacionExcel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cancelacion/excel`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteCancelacion(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cancelacion`, data, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteDescuentos(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/descuentos`, data, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteDescuentosExcel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/descuentos/excel`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function ReporteVentaDolares(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/ventasdolares`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)

    .catch((error) => handleResponse(error));
}
function ReporteCashCertificado(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cashcertificado`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)

    .catch((error) => handleResponse(error));
}
function liquidacion(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/liquidacion`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function cobro(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/cobro`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function ComisionesRepsXproductos(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/reps/ventas/productos`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reporteRepComisiones(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/excel/reps/ventas`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function cancelacionTotal(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}cancelacion/total`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function formasDePago() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}agencia/payment`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function monedas() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}monedas`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function monedaslist() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}monedas/list`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function pickupService(id, tipo) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}pickups/servicio/${id}/${tipo}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function cancelacion_monedas(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}monedas/cancelacion`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function multiples(data) {
  const requestOptions = {
    headers: authHeader(),
    responseType: 'arraybuffer',
  };
  return axiosApi
    .post(`${API_BACK}pdfs/global/multiple`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function updateLocation(data) {
  const requestOptions = {
    headers: authHeader(),
    responseType: 'arraybuffer',
  };
  return axiosApi
    .post(`${API_BACK}update/locacion`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reenviarEmail(id) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}email/cliente/${id}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function emailAntifraud(id) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}email/cliente/antifraude/${id}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reenviarEmailRechazado(id) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}email/cliente/rechazado/${id}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reenviarCotizacion(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}email/cliente/cotizacion`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reenviarCotizacionPaypal(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}email/cliente/cotizacion-paypal`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reenviarCotizacionClip(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}email/cliente/cotizacion-clip`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function locacionesAll($agency) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}locaciones?agency=${$agency}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function hotelesVenta() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}hotelesventa`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

const getDestinations = () => {
  return axiosApi
    .get('destinos', {
      headers: authHeader(),
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

function hotelesLocacion(id) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}usuarios/locacion/${id}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function confirmacionTranporte(data) {
  const requestOptions = {};
  return axiosApi
    .put(
      `https://cyc-oasishoteles.com/api/rf/index.php/Cmaya/setConfirmation`,
      data,
      requestOptions
    )
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function cancelacionTransporte(data) {
  const requestOptions = {};
  return axiosApi
    .put(
      `https://cyc-oasishoteles.com/api/rf/index.php/Cmaya/confirmCancelation`,
      data,
      requestOptions
    )
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function proveedoresProduccion(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}proveedores/produccion`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function getReserva(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/administrar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function EditarReserva(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/administrar/editarreserva`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function EditarServicio(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/administrar/editarservicio`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function FormasPago(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/administrar/formaspago`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function confirmacionServicioPDF(all) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}confirmacion/servicio/pdf`, all, {
      ...requestOptions,
      responseType: 'arraybuffer',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function servicioProveedor(proveedor) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}proveedor/servicio/${proveedor}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function firmasretro(e) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}firmasretro/${e}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function periodoCorte(month) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}presupuesto/periodo/corte/list?${month}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reportePresupuestoRepCorte(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}presupuesto/rep/corte`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reportePresupuestoRepCorteExcel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}presupuesto/rep/corte/excel`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteComisionesFormulasExcel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}presupuesto/rep/corte/excel/formulas`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reportePresupuestoGradoAlcanceJavier(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}presupuesto/rep/grado-alcance-javier/excel`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reportePresupuestoLocacionExcel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}presupuesto/locacion/excel`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reportePresupuestoGradoAlcanceExcel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}presupuesto/rep/grado-alcance/excel`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function UserhasProvider(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}user/proveedor`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function PaisVenta(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/pais/venta`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reportePaisVentaExcel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/pais/venta/excel`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteGradoAlcanceDias(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/grado-ranqueo`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function reporteRanqueoDias(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/ranqueo-dias`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function reporteCuponesAutorizados(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}promo/code/autorizacion`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function restaurarReserva(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}administrar/reserva/restaurar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function presupuestoValidate(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}validar/presupuesto`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

const reporteVentasProduccion = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reportes/sales-production-by-year`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const reporteVentasProduccionCanal = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reportes/sales-production-by-channel-year`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const convertionCampaigns = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reportes/convertions-campaings`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const getAllowMinors = (id, productType) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .get(`${API_BACK}reservas/get-allow-minors/${id}/${productType}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const getAgenciaCliente = () => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .get(`${API_BACK}agencia/cliente`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const getAgenciaLocacion = () => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .get(`${API_BACK}agencia/locacion`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const getPromoCode = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}cupones/descuento`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const buscarAgencia = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}agencia/search`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const AgruparCompartido = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}transporte/agrupar-compartidos/get`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const AgruparCompartidoVuelos = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}transporte/agrupar-compartidos/vuelos`, data, {
      requestOptions,
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const TraerZonas = () => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}transporte/zonas/get`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const cotizarxZona = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}transporte/cotizarxzona`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const buscarTemporada = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}transporte/buscar-temporada`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const AgruparCompartidoStore = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}transporte/agrupar/crear`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const QuitarAgrupacion = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}transporte/agrupar/eliminar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const reporteAgrupacionTransporte = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}transporte/agrupar/reporte`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const reporteAgrupacionTransporteExcel = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}transporte/agrupar/reporte/excel`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const reporteAgrupacionTransporteEliminar = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}transporte/agrupar/reporte/eliminar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const reporteHampton = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/hampton`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const reporteComparativoAnual = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/comparativo/anual`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const reporteComparativoAnualxMes = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/comparativo/mensual`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
function reporteEdicion(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte/items/editados`, data, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function politicasAll() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}politicas/get`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

const commissionsbyRep = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}reporte/commissions-by-channel`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const searchUserByMonth = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}searchUserByMonth`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const searchUserByMonthInfo = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}searchUserByMonthInfo`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const inceptivesReps = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}reporte/incentivos-reps`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const ReporteTarifarioExcursiones = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}reportes/reporte-tarifario-excursion`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const Agencias = () => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}userxagencia`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const DockTransportation = (id) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}dockTransportation/${id}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const validateDisponibilidad = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}integraciones/validateDisponibilidad`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const integracionesHoteles = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}integraciones/hoteles`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const Grupos = (id) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}grupos/get`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const searchSubManagerByMonth = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}comision-comercial/searchUserByMonth`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const searchSubManagerByMonthInfo = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}comision-comercial/searchUserByMonthInfo`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const searchGroupByMonth = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}grupos/by-month`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const bothServicesList = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}report/all-services`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const searchSupervisorByMonth = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}comision-supervisor/searchUserByMonth`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const reporteSupervisores = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}reporteSupervisores`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const getExcelCommission = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reporte-comisiones-unificado`, data, {
      ...requestOptions,
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => {
      handleResponse(error);
    });
};
const UsersByRol = () => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}users-list-all-by-rol-concat`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const inceptivesProviders = () => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}inceptives/providers-list`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const getReservaciones = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reservasDiarioVentas`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const obtainMostSoldItems = (data) => {
  const requestOptions = {
    headers: authHeader(),
    responseType: 'blob',
  };
  return axiosApi
    .post(`${API_BACK}report/most-selled-last-week`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const logLogin = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}login-logs`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const resendQuotationPendingEmail = (coupon) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}quotation-pending-email/${coupon}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const handleDeleteReservation = (reservation_id) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .delete(`${API_BACK}reservations/inactivate/${Number(reservation_id)}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const getNimsList = () => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .get(`${API_BACK}months-nim/list`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const repsByRoleId = (payload) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}users-main-roles-by-id`, payload, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const salesPlaceByCriteria = (payload) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}sales-place-by-criteria`, payload, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const actualizarOcupacion = (payload) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}sales-place-occupancy`, payload, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const getLocation = (payload) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}current-location`, payload, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const getLocationByHotel = (payload) => {
  const requestOptions = {
    headers: authHeader(),
  };

  return axiosApi
    .post(`${API_BACK}locations/list-by-hotel`, payload, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const getExcelCommercial = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}ReportSalesChannel`, data, { ...requestOptions, responseType: 'blob' })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const RetroactiveReservations = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}RetroactiveReservations`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const getAllCurrency = () => {
  return axiosApi
    .get(`${API_BACK}monedas`)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const validateComboDiscos = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}discs/combo/validate`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const undoComboDiscos = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}discs/combo/undo`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

const counterServiceByMonth = (day, data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}booking-manager/counter?day=${day}`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const getActivitiesById = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/get_activities_by_id`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const saveCancellationRequest = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/save_cancellation_request`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const deleteCancellationRequest = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/delete_cancellation_request`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const confirmCancellationRequest = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/confirm_cancellation_request`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const sendEmailConfirmCancellation = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/send_email_item_cancellation`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const sendReserveReprogramming = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/send_reserve_reprogramming`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const validatePaymentMethod = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/validate_payment_method`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
};
const sendConfirmationClient = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/send_confirmation_client`, data, requestOptions)
    .then((reponse) => reponse)
    .catch((error) => handleResponse(error));
};
function saveFirmaSupervisor(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}saveFirmaSupervisor`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function saveFirmaRep(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}saveFirmaRep`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function saveFirmaCliente(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}saveFirmaCliente`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function currencyConversion(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}currencyConversion`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function confirmClient(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}reserva/confirmar-cliente`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function HotelsFilter() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}hotels/filters`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function periodoAsignacion(month) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}presupuesto/periodo/corte/listAsignature?${month}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
// eslint-disable-next-line import/prefer-default-export
export const reserva = {
  getReservaciones,
  getExcelCommission,
  ComisionesPagadas,
  gruposTransporte,
  codeProveedor,
  reportePresupuestoEXCELxPeriodo,
  reportePresupuestoPDF,
  reportePresupuestoEXCEL,
  incentivesProveedores,
  buscarProveedores,
  testPDF,
  downloadPDF,
  listaLocaciones,
  crearPresupuesto,
  reportePresupuesto,
  facturaEXCEL,
  productividadXproveedorPDF,
  productividadXproveedorEXCEL,
  proveedoresProduccion,
  cuponesCostosPDF,
  cuponesCostosEXCEL,
  cuponesConciliadosPDF,
  cuponesConciliadosEXCEL,
  cuponesConciliados,
  excelContabilidad,
  cancelacionTransporte,
  confirmacionTranporte,
  excelTwo,
  reporteTransporte,
  editarFactura,
  buscarFacturas,
  adicionarCupon,
  AdicionarFactura,
  updateLocation,
  locacionesAll,
  excelExportar,
  excelExportarPagos,
  reporteRepComisiones,
  reenviarEmail,
  emailAntifraud,
  multiples,
  confirmacion,
  canales,
  comisiones,
  save,
  saveFirma,
  enviarCorreos,
  show,
  update,
  successPage,
  updateByItem,
  showByID,
  products,
  hoteles,
  locaciones,
  locacionUser,
  hotelesReporte,
  proveedoresReporte,
  productosReporte,
  tipoCambioDia,
  reporteGlobal,
  reporteGlobalPdf,
  reporteComissionReps,
  reps,
  excel,
  excelReps,
  pagoRestante,
  cancelacionTotal,
  formasDePago,
  monedas,
  cancelacion_monedas,
  pickupService,
  reporteProduccion,
  ComisionesRepsXproductos,
  reporteGerentes,
  ReporteVentaDolares,
  liquidacion,
  cobro,
  reporteCancelacion,
  supervisor,
  reporteCancelacionExcel,
  reporteTransporteCoste,
  reporteTransportellegada,
  reporteTransporteops,
  getReserva,
  FormasPago,
  cuponesNoConciliados,
  cuponesNoConciliadosEXCEL,
  cuponesNoConciliadosPDF,
  confirmacionServicioPDF,
  cuponesConciliadosvalorEXCEL,
  cuponesConciliadosvalorAuditoriaEXCEL,
  servicioProveedor,
  monedaslist,
  reporteTransporteTraslados,
  firmasretro,
  saveFirmaRetro,
  reporteTransporteopsmail,
  EditarReserva,
  EditarServicio,
  cuponesCostosEXCELUSD,
  costoExcursionesMoneda,
  reporteDiarioVenta,
  hotelesVenta,
  hotelesLocacion,
  verPresupuestos,
  repList,
  repLocacionPresupuesto,
  repLocacionPresupuestoGet,
  repLocacionPresupuestoDelete,
  reporteDescuentos,
  reporteDescuentosExcel,
  excelComisionesDetailReps,
  ComisionesDetailReps,
  periodosCortes,
  periodosCortesAlta,
  repLocacionPresupuestoUpdate,
  periodoCorte,
  reportePresupuestoRepCorte,
  reportePresupuestoRepCorteExcel,
  repLocacionPresupuestoGetFecha,
  UserhasProvider,
  PaisVenta,
  reportePickups,
  reportePaisVentaExcel,
  reportePresupuestoGradoAlcanceExcel,
  ReporteCashCertificado,
  reportePresupuestoLocacionExcel,
  reportePresupuestoGradoAlcanceJavier,
  reporteProduccionAnual,
  reporteGradoAlcanceDias,
  reporteRanqueoDias,
  reporteCuponesAutorizados,
  reporteComisionesFormulasExcel,
  restaurarReserva,
  presupuestoValidate,
  ReporteProduccionAnualProveedor,
  reportePresupuestosLocacionVenta,
  reenviarCotizacion,
  reenviarCotizacionPaypal,
  reenviarCotizacionClip,
  reporteVentasProduccion,
  getAllowMinors,
  convertionCampaigns,
  reporteVentasProduccionCanal,
  getAgenciaCliente,
  getAgenciaLocacion,
  buscarAgencia,
  getPromoCode,
  AgruparCompartido,
  TraerZonas,
  cotizarxZona,
  buscarTemporada,
  AgruparCompartidoStore,
  QuitarAgrupacion,
  reporteAgrupacionTransporte,
  reporteAgrupacionTransporteExcel,
  reporteAgrupacionTransporteEliminar,
  reporteComparativoAnual,
  reporteHampton,
  reporteComparativoAnualxMes,
  reporteEdicion,
  politicasAll,
  commissionsbyRep,
  inceptivesReps,
  searchUserByMonth,
  searchUserByMonthInfo,
  Agencias,
  ReporteTarifarioExcursiones,
  DockTransportation,
  validateDisponibilidad,
  integracionesHoteles,
  reenviarEmailRechazado,
  Grupos,
  searchSubManagerByMonth,
  searchSubManagerByMonthInfo,
  searchGroupByMonth,
  bothServicesList,
  searchSupervisorByMonth,
  reporteSupervisores,
  UsersByRol,
  inceptivesProviders,
  AgruparCompartidoVuelos,
  obtainMostSoldItems,
  logLogin,
  resendQuotationPendingEmail,
  handleDeleteReservation,
  getNimsList,
  getDestinations,
  repsByRoleId,
  salesPlaceByCriteria,
  actualizarOcupacion,
  saveBooking,
  getLocation,
  getLocationByHotel,
  getExcelCommercial,
  RetroactiveReservations,
  getAllCurrency,
  validateComboDiscos,
  undoComboDiscos,
  counterServiceByMonth,
  getActivitiesById,
  saveFirmaSupervisor,
  saveFirmaRep,
  saveCancellationRequest,
  deleteCancellationRequest,
  confirmCancellationRequest,
  sendEmailConfirmCancellation,
  sendReserveReprogramming,
  validatePaymentMethod,
  saveFirmaCliente,
  sendConfirmationClient,
  confirmBudget,
  currencyConversion,
  HotelsFilter,
  confirmClient,
  periodoAsignacion,
};
